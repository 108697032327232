/** @jsx jsx */
import { memo, useState, useCallback } from 'react'
import { jsx, Label, Button, Heading, Flex, Box, Text, Grid } from 'theme-ui'
import { FieldArray, FastField as Field } from 'formik'

import DJ from '@components/forms/DJ'
import { initialDJValue } from '@components/Admin'
import { AdminTextarea } from '@components/forms/TextArea'

enum TSelectedSchedule {
  RADIO = 'radio',
  CLUB = 'club',
}

const Tab = ({ value, selected, children, handleClick }) => (
  <button
    type="button"
    sx={{
      p: 2,
      bg: 'rgba(0,0,0,0.1)',
      border: 'none',
      fontStyle: selected ? 'italic' : 'normal',
      cursor: 'pointer',
      '&:disabled': {
        color: 'black',
      },
    }}
    disabled={selected}
    onClick={() => handleClick(value)}
  >
    {children}
  </button>
)

export const Schedules = ({
  clubSchedule,
  socialLinks,
  clubText,
  radioText,
}) => {
  const [selectedSchedule, setSchedule] = useState<TSelectedSchedule>(
    TSelectedSchedule.RADIO
  )

  const handleClick = useCallback(
    (schedule) => {
      setSchedule(schedule)
    },
    [setSchedule]
  )

  return (
    <Grid sx={{ alignContent: 'flex-start' }}>
      <Heading>Schedules</Heading>

      <Flex>
        <Tab
          value={TSelectedSchedule.CLUB}
          selected={TSelectedSchedule.CLUB === selectedSchedule}
          handleClick={handleClick}
        >
          Club
        </Tab>
        <Tab
          value={TSelectedSchedule.RADIO}
          selected={TSelectedSchedule.RADIO === selectedSchedule}
          handleClick={handleClick}
        >
          Radio
        </Tab>
      </Flex>

      <Box>
        {selectedSchedule === TSelectedSchedule.RADIO && (
          <div>
            <Label>General radio text</Label>
            <Field component={AdminTextarea} name="radioText" id="radioText" />
            <FieldArray
              name="socialLinks"
              validateOnChange={false}
              render={({ push, remove }) => (
                <Grid sx={{ p: 1, bg: 'rgba(0,0,0,0.05)', gap: 1 }}>
                  <Text as="div" sx={{ fontSize: 3 }}>
                    Radio Schedule
                  </Text>
                  {socialLinks && socialLinks.length > 0 ? (
                    socialLinks.map((dj, index) => (
                      <DJ
                        key={`social-${index}`}
                        dj={dj}
                        field="socialLinks"
                        index={index}
                        arrayHelpers={{ push, remove }}
                        last={index === socialLinks.length - 1}
                      />
                    ))
                  ) : (
                    <Box>
                      <Text as="div">No radio schedule yet.</Text>
                      <Button
                        variant="small"
                        sx={{ display: 'block' }}
                        onClick={() => push(initialDJValue)}
                      >
                        Add radio DJ
                      </Button>
                    </Box>
                  )}
                </Grid>
              )}
            />
          </div>
        )}

        {selectedSchedule === TSelectedSchedule.CLUB && (
          <div>
            <Label>General club text</Label>
            <Field component={AdminTextarea} name="clubText" id="clubText" />
            <FieldArray
              name="clubSchedule"
              validateOnChange={false}
              render={({ push, remove }) => (
                <Grid sx={{ p: 1, bg: 'rgba(0,0,0,0.05)', gap: 1 }}>
                  <Text as="div" sx={{ fontSize: 3 }}>
                    Club Schedule
                  </Text>
                  {clubSchedule && clubSchedule.length > 0 ? (
                    clubSchedule.map((dj, index) => (
                      <DJ
                        key={`club-${index}`}
                        dj={dj}
                        field="clubSchedule"
                        index={index}
                        arrayHelpers={{ push, remove }}
                        last={index === clubSchedule.length - 1}
                      />
                    ))
                  ) : (
                    <Box>
                      <Text as="div">No club schedule yet.</Text>
                      <Button
                        variant="small"
                        sx={{ display: 'block' }}
                        onClick={() => push(initialDJValue)}
                      >
                        Add club DJ
                      </Button>
                    </Box>
                  )}
                </Grid>
              )}
            />
          </div>
        )}
      </Box>
    </Grid>
  )
}
