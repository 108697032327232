import React from 'react'
import { Textarea as StyledTextarea } from 'theme-ui'
import { animated } from 'react-spring'

const Textarea = React.forwardRef(
  ({ field, form, type = 'text', ...props }, ref) => (
    <StyledTextarea
      type={type}
      {...field}
      sx={{
        p: 3,
        borderRadius: 0,
        border: '1px solid black',
        fontFamily: 'body',
        '&:nth-of-type(1)': {
          borderBottom: 'none',
        },
        '&:focus': {
          outline: 'none',
          boxShadow: 'inset 0 0 0 1px black',
        },
        '&::placeholder': {
          color: 'black',
          opacity: 0.6,
        },
      }}
      {...props}
      ref={ref}
    />
  )
)

const AnimatedTextarea = animated(Textarea)

export const NoBorderInput = React.forwardRef((props, ref) => (
  <AnimatedTextarea
    ref={ref}
    {...props}
    sx={{
      fontFamily: 'body',
      border: 'none',
      borderRadius: 0,
      p: 0,
      '&:focus': {
        outline: 'none',
      },
      '&::placeholder': {
        color: 'black',
        opacity: 0.6,
      },
    }}
  />
))

export const AdminTextarea = (props) => {
  return (
    <Textarea
      {...props}
      sx={{
        p: 2,
        fontFamily: 'body',
        borderRadius: 0,
        border: '1px solid black',
        minHeight: '12em',
        '&:focus': {
          outline: 'none',
          boxShadow: 'inset 0 0 0 1px black',
        },
        '&::placeholder': {
          color: 'black',
          opacity: 0.6,
        },
      }}
    />
  )
}

export default Textarea
