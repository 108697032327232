/** @jsx jsx */
import { jsx, Button, Heading, Box, Text, Flex, Grid } from 'theme-ui'
import { useFormikContext, FastField as Field, ErrorMessage } from 'formik'
import { AdminInput as Input } from '@components/forms/Input'
import { memo } from 'react'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@reach/disclosure'

import { EMessage } from '@components/forms/EMessage'

export const GeneralSettings = memo(
  ({ showSaved, color, status, showLinksOffline, showChat, isSubmitting }) => {
    return (
      <Grid
        sx={{
          alignContent: 'flex-start',
          justifyItems: 'flex-start',
        }}
      >
        <Heading>General Settings</Heading>

        <Grid sx={{ gridTemplateColumns: 'repeat(3, max-content)' }}>
          <label>
            <Field
              name="status"
              type="radio"
              value="offline"
              checked={status === 'offline'}
            />
            Offline
          </label>
          <label>
            <Field
              name="status"
              type="radio"
              value="live"
              checked={status === 'live'}
            />
            Live
          </label>
          <label>
            <Field
              name="status"
              type="radio"
              value="rerun"
              checked={status === 'rerun'}
            />
            Rerun
          </label>
        </Grid>

        <label>
          <Text>Color</Text>
          <Flex sx={{ alignItems: 'center' }}>
            {color && (
              <Box
                sx={{
                  border: '1px solid black',
                  width: '2em',
                  height: '2em',
                  mr: 2,
                  bg: color,
                }}
              ></Box>
            )}
            <Field name="color" placeholder="#000" component={Input} />
          </Flex>
        </label>

        <label>
          <Field
            name="showLinksOffline"
            type="checkbox"
            checked={showLinksOffline}
          />
          Show radio schedule while offline?
        </label>

        <label>
          <Field name="showChat" type="checkbox" checked={showChat} />
          Enable chat?
        </label>

        <Box sx={{ bg: 'rgba(0,0,0,0.05)', p: 2 }}>
          <Disclosure>
            <DisclosureButton sx={{ mb: 1 }}>
              Toggle advanced options
            </DisclosureButton>
            <DisclosurePanel>
              <Grid>
                <Text sx={{ color: 'red' }}>
                  Don't change these unless you know what you're doing!
                </Text>
                <label>
                  LIVE Stream URL
                  <Field
                    name="streamURL"
                    placeholder="LIVE stream URL"
                    component={Input}
                  />
                </label>
                <ErrorMessage name="streamURL" />

                <label>
                  Test stream URL
                  <Field
                    name="testStreamURL"
                    placeholder="Test stream URL"
                    component={Input}
                  />
                </label>
                <EMessage name="testStreamURL" />

                <label>
                  Rerun stream URL
                  <Field
                    name="rerunStreamURL"
                    placeholder="Rerun stream URL"
                    component={Input}
                  />
                </label>
                <EMessage name="rerunStreamURL" />
              </Grid>
            </DisclosurePanel>
          </Disclosure>
        </Box>

        <label>
          Password
          <Field name="password" type="password" component={Input} />
        </label>

        <Box mt={2}>
          <EMessage name="password" />
          <EMessage name="stream" />
          <EMessage name="color" />
        </Box>

        <Button
          type="submit"
          disabled={isSubmitting}
          sx={{
            m: 0,
            display: 'block',
            border: 'none',
            color: 'white',
            bg: 'black',
          }}
        >
          Save
        </Button>
        {showSaved && <Text>Saved!</Text>}
      </Grid>
    )
  }
)
