/** @jsx jsx */
import { jsx, Button, Box, Text, Flex, Grid } from 'theme-ui'
import { FieldArray, FastField as Field } from 'formik'
import { AdminInput as Input } from '@components/forms/Input'
import React, { memo } from 'react'
import range from 'lodash/range'

import { EMessage } from '@components/forms/EMessage'
import { initialDJValue, initialLinkValue } from '@components/Admin'

const TimeOptions = memo(() => (
  <>
    {range(0, 24).map((v, i) => {
      const time = `${v}:00`
      return (
        <option key={`time-${i}`} value={time}>
          {time}
        </option>
      )
    })}
  </>
))

const DJ = ({ dj, index, last, field, arrayHelpers }) => {
  const name = `${field}.${index}`
  return (
    <Grid
      sx={{
        alignItem: 'center',
        justifyItems: 'center',
      }}
    >
      <Grid
        sx={{
          gridTemplateColumns: '1fr max-content',
          gap: 0,
          width: '100%',
          bg: 'rgba(0,10,50,0.1)',
        }}
      >
        <Grid
          sx={{
            gridRowGap: 0,
            p: 2,
            gridColumnGap: 2,
            gridTemplateColumns: 'max-content 1fr 1fr max-content',
          }}
        >
          <Box>
            {field === 'socialLinks' ? (
              <Field name={`${name}.time`} as="select">
                <TimeOptions />
              </Field>
            ) : (
              <Field
                name={`${name}.time`}
                placeholder="July 16"
                component={Input}
              />
            )}

            <EMessage name={`${name}.time`} />
          </Box>

          <Box sx={{ gridColumn: 'span 2' }}>
            <Field
              name={`${name}.name`}
              placeholder="DJ name"
              component={Input}
            />

            <EMessage name={`${name}.name`} />
          </Box>

          <FieldArray
            name={`${name}.links`}
            render={(linkHelpers) => {
              const onCreate = () => linkHelpers.push(initialLinkValue)
              return (
                <div sx={{ gridColumn: 'span 4', mt: 2 }}>
                  {dj.links && dj.links.length > 0 ? (
                    dj.links.map((_, linkIndex) => {
                      const linkName = `${name}.links.${linkIndex}`
                      return (
                        <Box
                          key={`link-${index}-${linkIndex}`}
                          sx={{ mb: [2, 0] }}
                        >
                          <Grid
                            sx={{
                              gridTemplateColumns: [
                                '1fr max-content',
                                '1fr 2fr max-content',
                              ],
                              gridTemplateAreas: [
                                `'name actions' 'url actions'`,
                                `'name url actions'`,
                              ],
                              gridGap: 0,
                            }}
                          >
                            <Field
                              name={`${linkName}.name`}
                              component={Input}
                              placeholder="FB / Insta etc."
                              sx={{ gridArea: 'name', borderRight: [null, 0] }}
                            />
                            <Field
                              name={`${linkName}.url`}
                              component={Input}
                              placeholder="URL"
                              sx={{ gridArea: 'url' }}
                            />
                            <Flex>
                              <button
                                type="button"
                                onClick={() => linkHelpers.remove(linkIndex)}
                                sx={{
                                  borderRadius: '2em',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  p: 0,
                                  border: 'none',
                                  color: 'white',
                                  alignSelf: 'center',
                                  cursor: 'pointer',
                                  background: 'none',
                                  ml: 2,
                                  gridArea: 'actions',
                                  svg: {
                                    height: '2em',
                                    fill: 'black',
                                  },
                                  '&:hover': {
                                    svg: {
                                      fill: 'red',
                                    },
                                  },
                                }}
                              >
                                <svg viewBox="0 0 24 24">
                                  <path fill="none" d="M0 0h24v24H0z" />
                                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z" />
                                </svg>
                              </button>
                              {linkIndex === dj.links.length - 1 && (
                                <button
                                  type="button"
                                  onClick={onCreate}
                                  sx={{
                                    borderRadius: '2em',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: 0,
                                    border: 'none',
                                    color: 'white',
                                    alignSelf: 'center',
                                    cursor: 'pointer',
                                    background: 'none',
                                    gridArea: 'actions',
                                    svg: {
                                      height: '2em',
                                      fill: 'black',
                                    },
                                    '&:hover': {
                                      svg: {
                                        fill: 'red',
                                      },
                                    },
                                  }}
                                >
                                  <svg viewBox="0 0 24 24">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm1 5h-2v4H7v2h4v4h2v-4h4v-2h-4V7z" />
                                  </svg>
                                </button>
                              )}
                            </Flex>
                          </Grid>

                          <EMessage name={`${linkName}.name`} />
                          <EMessage name={`${linkName}.url`} />
                        </Box>
                      )
                    })
                  ) : (
                    <Box>
                      <Text as="div">No links yet.</Text>
                      <Button variant="small" onClick={onCreate}>
                        Add a link
                      </Button>
                    </Box>
                  )}
                </div>
              )
            }}
          />
        </Grid>
        <button
          type="button"
          onClick={() => arrayHelpers.remove(index)}
          sx={{
            alignSelf: 'center',
            borderRadius: '2em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            border: 'none',
            color: 'white',
            cursor: 'pointer',
            bg: 'red',
            svg: {
              height: '2em',
              fill: 'black',
            },
            '&:hover': {
              bg: 'white',
            },
          }}
        >
          <svg viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
            <path fill="none" d="M0 0h24v24H0z" />
          </svg>
        </button>
      </Grid>

      {last && (
        <Box>
          <Button
            variant="small"
            onClick={() => arrayHelpers.push(initialDJValue)}
          >
            Add another DJ
          </Button>
        </Box>
      )}
    </Grid>
  )
}

export default DJ
