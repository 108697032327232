/** @jsx jsx */
import React, { useState, useRef } from 'react'
import { jsx, Grid, Spinner } from 'theme-ui'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import shallow from 'zustand/shallow'

import { GeneralSettings } from '@components/GeneralSettings'
import { Schedules } from '@components/Schedules'
import { updateAdmin } from '@contexts/Socket'
import Layout from '@components/Layout'
import { useStore } from '@stores/3d'

export const initialLinkValue = {
  name: 'Soundcloud',
  url: '',
}

export const initialDJValue = {
  time: '',
  name: '',
  links: [initialLinkValue],
}

const schema = yup.object().shape({
  status: yup.string().oneOf(['offline', 'live', 'rerun']).required(),
  password: yup.string().required('Enter a password'),
  color: yup.string().required('Please select a color'),
  showLinksOffline: yup.boolean(),
  showChat: yup.boolean(),
  radioText: yup.string(),
  socialLinks: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Please enter a DJ name'),
      links: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .required('Enter a name for this URL (ie. Facebook)'),
          url: yup
            .string()
            .url('Enter a valid URL')
            .required('Enter a valid URL'),
        })
      ),
    })
  ),
  clubText: yup.string(),
  clubSchedule: yup.array().of(
    yup.object().shape({
      time: yup.string().required('Please enter a time'),
      name: yup.string().required('Please enter a DJ name'),
      links: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .required('Enter a name for this URL (ie. Facebook)'),
          url: yup
            .string()
            .url('Enter a valid URL')
            .required('Enter a valid URL'),
        })
      ),
    })
  ),
  streamURL: yup
    .string()
    .url('Please enter a valid stream URL')
    .required('Please enter a valid stream URL'),
  testStreamURL: yup
    .string()
    .url('Please enter a valid stream URL')
    .required('Please enter a valid stream URL'),
  rerunStreamURL: yup
    .string()
    .url('Please enter a valid stream URL')
    .required('Please enter a valid stream URL'),
})

const Admin = () => {
  const {
    color,
    live,
    rerun,
    streamURL,
    testStreamURL,
    rerunStreamURL,
    streamcount,
    socialLinks,
    clubSchedule,
    clubText,
    radioText,
    showLinksOffline,
    showChat,
  } = useStore(
    ({
      streamcount,
      color,
      live,
      rerun,
      clubSchedule,
      clubText,
      radioText,
      streamURL,
      testStreamURL,
      rerunStreamURL,
      socialLinks,
      showLinksOffline,
      showChat,
    }) => ({
      streamcount,
      color,
      live,
      rerun,
      clubText,
      radioText,
      clubSchedule,
      socialLinks,
      streamURL,
      testStreamURL,
      rerunStreamURL,
      showLinksOffline,
      showChat,
    }),
    shallow
  )

  React.useEffect(() => {
    document.body.style.backgroundColor = color
  }, [color])

  const [showSaved, setShowSaved] = useState(false)
  const timer = useRef()
  const setSaved = () => {
    clearTimeout(timer.current)
    setShowSaved(true)
    timer.current = setTimeout(() => {
      setShowSaved(false)
    }, 5000)
  }

  return (
    <Layout sx={{ width: '100%' }} hideFooter>
      {streamcount === 0 ? (
        <Spinner />
      ) : (
        <Grid sx={{ p: [3, 4], fontSize: '16px' }}>
          <Formik
            onSubmit={({ password, status, ...rest }, { setSubmitting }) => {
              updateAdmin(password, {
                ...rest,
                live: status === 'live',
                rerun: status === 'rerun',
              })
              setSaved()
              setSubmitting(false)
            }}
            initialValues={{
              status: live ? 'live' : rerun ? 'rerun' : 'offline',
              password: '',
              color,
              socialLinks,
              clubSchedule,
              streamURL,
              testStreamURL,
              rerunStreamURL,
              showLinksOffline,
              showChat,
              radioText,
              clubText,
            }}
            validationSchema={schema}
          >
            {({
              values: {
                color,
                status,
                showLinksOffline,
                showChat,
                socialLinks,
                clubSchedule,
                clubText,
                radioText,
              },
              isSubmitting,
            }) => (
              <Form>
                <Grid
                  sx={{
                    gridTemplateColumns: [
                      '1fr',
                      '1fr',
                      '1fr',
                      'max-content 1fr',
                    ],
                    rowGap: 5,
                    columnGap: 4,
                  }}
                >
                  <GeneralSettings
                    showSaved={showSaved}
                    isSubmitting={isSubmitting}
                    color={color}
                    status={status}
                    showLinksOffline={showLinksOffline}
                    showChat={showChat}
                  />

                  <Schedules
                    socialLinks={socialLinks}
                    clubSchedule={clubSchedule}
                    clubText={clubText}
                    radioText={radioText}
                  />
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      )}
    </Layout>
  )
}

export default Admin
